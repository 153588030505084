export const productSortItems = [
  { 
    value: null, 
    text: 'Default sort', 
  },
  {
    value: 'views',
    text: 'By popularity (Ascending)',
  },
  {
    value: '-views',
    text: 'By popularity (Descending)',
  },
  { 
    value: 'price', 
    text: 'By price (lowest-highest)',
  },
  { 
    value: '-price', 
    text: 'By price (highest-lowest)', 
  },
  { 
    value: 'title', 
    text: 'By name (A-Z)', 
  },
  { 
    value: '-title', 
    text: 'By name (Z-A)', 
  },
  { 
    value: 'created_at', 
    text: 'Date added (Newer - Older)',
  },
  { 
    value: '-created_at', 
    text: 'Date added (Older - Newer)',
  },
];